<template>
  <Dialog
    v-if="
      $parent.activeClient.dni &&
      currentPlayData &&
      currentPlayData.plays.length
    "
    header="Método de Pago"
    :style="{
      width: '100vw',
      maxWidth: '1400px',
      minHeight: '90vh',
      backgroundColor: '#ffffff',
      maxHeight: '100vh'
    }"
    :breakpoints="{ '640px': '100vw', '1400px': '95vw' }"
    maximizable
    :modal="true"
    @show="onShow"
    @hide="onHide"
    class="pay-options-dialog p-0 m-0"
  >
    <div class="grid w-full">
      <div class="col-12 lg:col-3 ml-1 lg:ml-0 max-h-full relative" style="min-width: 360px;">
        <payment-summary />
      </div>
      <div class="col-12 md:col">
        <TabView class="tabview-custom" v-model:activeIndex="selectedTabIndex">
          <!-- Panel Wallet -->
          <TabPanel>
            <template #header>
              <div
                @click="selectPayMethod()"
                class="flex flex-wrap align-items-center justify-content-center"
              >
                <i class="titleicon-pay-tab pi pi-wallet mr-1"/>
                <span class="text-center ml-1">Wallet</span>
              </div>
            </template>
            <WalletPaymentMethod  
            @cleaningFunction="cleaningFunction"
            @registerWalletPay="registerWalletPay"
            @sendWalletClientPay="sendWalletClientPay"
            @sendWalletClientVerificationCode="sendWalletClientVerificationCode"
            @verificationSMSCode="verificationSMSCode"
            :sendVerificationCode="sendVerificationCode"
            :isVerifing="isVerifing"
            />
          </TabPanel>
          <!---->
          <TabPanel>
            <template #header>
              <div
                @click="selectPayMethod('Bolívares')"
                class="flex flex-wrap align-items-center justify-content-center"
              >
                <i class="pi titleicon-pay-tab text-center mr-1 pb-2">Bs</i>
                <span class="text-center ml-1">Pago Móvil</span>
              </div>
            </template>
            <div class="flex flex-column justify-content align-items-center payment-form-container">
              <!--<div
                v-if="this.pay_method_select && this.payMethods.payMethods"
                class="flex flex-wrap justify-content align-items-center"
              >
                <div
                  v-for="data in this.payMethods.payMethods.data"
                  :key="data.id"
                >
                  <div
                    class="payment-method-button payButton mb-3 ml-3"
                    v-if="
                      data.name.indexOf('Efectivo') < 0 &&
                      data.currency_acronym == 'VES' &&
                      data.name.indexOf('Tarjeta')
                    "
                    @click="
                      payMethodSelected(
                        data.id,
                        data.fk_payment_method_structure,
                        data.convert_amount
                      )
                    "
                  >
                    {{ data.name }}
                  </div>
                </div>
              </div>-->
              <FormPayment
                v-if="!pay_method_select"
                @registerTransferenciaVes="registerTransferenciaVes"
                @successTransferenceVes="successTransferenceVes"
                :pay_method_structure="pay_method_structure"
                :amount_to_pay="amount_to_pay"
              />
            </div>
          </TabPanel>


          <!-- TO DO
          Is commented bc we won't be able to get cripto payments YET -->
          <!-- <TabPanel>
            <template #header>
              <div
                @click="selectPayMethod()"
                class="flex flex-wrap align-items-center justify-content-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="28"
                  viewBox="0 0 22 28"
                  fill="none"
                  class="title-pay-tab mr-1"
                >
                  <path
                    d="M21.6707 15.45C21.3262 14.7313 20.7955 14.1 19.7343 13.5688C19.1858 13.2875 18.5966 13.0771 17.9839 12.9438C18.3962 12.77 18.7753 12.538 19.1072 12.2563C19.5951 11.8607 19.9648 11.3598 20.1822 10.8C20.3823 10.2311 20.4641 9.63348 20.4234 9.0375C20.3476 7.9875 20.1202 6.96875 19.5138 6.2375C18.9074 5.50625 18.0529 4.94375 16.9227 4.55C16.0544 4.25 15.1654 4.0625 13.7872 3.99375V0H11.0307V4H8.82555V0H6.00016V4H0V7H1.92058C2.52011 7 2.92669 7.05 3.13342 7.14375C3.32578 7.21839 3.48395 7.35081 3.58135 7.51875C3.67094 7.675 3.71228 8.04375 3.71228 8.6125V19.4375C3.71228 20 3.67094 20.3625 3.58135 20.525C3.49177 20.6875 3.44353 20.8313 3.2299 20.9188C3.01627 21.0063 3.00938 21 2.41674 21H0.597466L0 24H5.99534V28H8.82072V24H11.0259V28H13.7824V23.975C15.5741 23.8938 16.849 23.6812 17.8757 23.3312C19.2057 22.8812 20.2256 22.225 20.9561 21.3625C21.6866 20.5 21.9209 19.1812 21.985 18.1625C22.0311 17.2563 21.9829 16.0875 21.6707 15.45ZM8.82072 7.375H11.0259V12H8.82072V7.375ZM8.82072 20.625V15H11.0259V20.625H8.82072ZM13.7824 7.61875C14.1959 7.775 14.4646 8.0875 14.7334 8.4125C15.0297 8.76875 15.1813 9.24375 15.1813 9.75C15.1813 10.2375 14.9814 10.6562 14.6645 11.0312C14.4026 11.3375 14.1959 11.55 13.7824 11.725V7.61875ZM15.767 19.2875C15.2295 19.7188 14.9194 19.9187 14.2441 20.15C14.0931 20.1982 13.9389 20.2379 13.7824 20.2687V15.0938C14.0519 15.1299 14.3143 15.2015 14.5611 15.3062C15.0986 15.5125 15.6085 15.7375 15.9255 16.1313C16.277 16.5835 16.4692 17.1217 16.4768 17.675C16.4748 18.3562 16.2818 18.875 15.765 19.2875H15.767Z"
                    :fill="selectedTabIndex === 1 ? 'white' : 'black'"
                  />
                </svg>
                <span class="text-center ml-1">Criptos</span>
              </div>
            </template>
            <div class="flex flex-column justify-content align-items-center">
              <div
                v-if="this.pay_method_select && this.payMethods.payMethods"
                class="flex flex-wrap justify-content align-items-center"
              >
                <div
                  v-for="item in this.payMethods.payMethods.data"
                  :key="item.id"
                >
                  <div
                    class="payment-method-button payButton binanceButton mb-3 ml-3"
                    v-if="
                      item.name.indexOf('Efectivo') < 0 &&
                      item.currency_acronym == 'BUSD' &&
                      item.name.indexOf('Cr') &&
                      this.webTest == false
                    "
                    @click="
                      payMethodSelected(
                        item.id,
                        item.fk_payment_method_structure,
                        item.convert_amount
                      )
                 amount_to_pay   "
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div> -->
                  <!-- Se comentó la opción de pago por web3 para la salida a producción. Luego de completar la función se subirá nuevamente -->
                  <!-- <div v-if="this.webTest == false"
                    class="payment-method-button payButton  mb-3 ml-3"
                    @click="showWeb3(1)"
                  >
                    BEP20 Transfer
                  </div> -->
                  <!-- <Web3Pay
                    v-if="this.webTest === true"
                    @registerBNBTransfer="registerBNBTransfer"
                    :amount_to_pay="amount_to_pay"
                  />
                </div>
              </div>
            </div>
            <FormPayment
              v-if="!this.pay_method_select"
              @registerTransferenciaVes="registerTransferenciaVes"
              :pay_method_structure="pay_method_structure"
              :amount_to_pay="amount_to_pay"
            />
          </TabPanel> -->
          <!-- END TODO  -->

          <!-- TO DO
          Is commented bc we won't be able to get dollar payments YET -->
          <!-- <TabPanel>
            <template #header>
              <div
                @click="selectPayMethod()"
                class="flex flex-wrap align-items-center justify-content-center align-content-center"
              >
                <i class="pi pi-dollar titleicon-pay-tab mr-1"></i>
                <span class="text-center ml-1">Divisas</span>
              </div>
            </template>
            <div class="flex flex-column justify-content align-items-center">
              <div
                v-if="this.pay_method_select && this.payMethods.payMethods"
                class="flex flex-wrap justify-content align-items-center"
              >
                <div
                  v-for="key in this.payMethods.payMethods.data"
                  :key="key.id"
                >
                  <div
                    class="payment-method-button payButton mb-3 ml-3"
                    v-if="
                      key.name.indexOf('Efectivo') < 0 &&
                      key.currency_acronym == 'USD' &&
                      key.name.indexOf('Tarjeta')
                    "
                    @click="
                      payMethodSelected(
                        key.id,
                        key.fk_payment_method_structure,
                        key.convert_amount
                      )
                    "
                    :class="{
                      zelleButton: key.name.toLowerCase().indexOf('zelle') >= 0,
                      paypalButton:
                        key.name.toLowerCase().indexOf('paypal') >= 0,
                    }"
                  >
                    {{ key.name }}
                  </div>
                </div>
              </div>
              <FormPayment
                v-if="!this.pay_method_select"
                @registerTransferenciaVes="registerTransferenciaVes"
                :pay_method_structure="pay_method_structure"
                :amount_to_pay="amount_to_pay"
              />
            </div>
          </TabPanel> -->
          <!-- END TODO  -->

          <!-- panel Efectivo -->
          <TabPanel
            v-if="false && (
              $route.path === '/ticketoffice' &&
              $store.getters['auth/role'] === 'taquilla')
            "
          >
            <template #header>
              <div
                @click="selectPayMethod('Efectivo')"
                class="flex flex-wrap align-items-center justify-content-center"
              >
                <i class="pi pi-money-bill titleicon-pay-tab mr-1"></i>
                <span class="text-center ml-1">Efectivo</span>
              </div>
            </template>
            <div
              class="flex flex-column align-items-center justify-content-center"
            >
              <SelectButton
                v-model="moneda"
                :options="pay_method_array"
                dataKey="id"
                optionDisabled="disable"
                class="currency-select-buttons"
              >
                <template #option="slotProps">
                  <div
                    class="flex flex-column align-items-center justify-content-center"
                    @click="payMethodDetail(slotProps.option.id)"
                  >
                    <span
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <!--<i class="mr-2" :class="slotProps.option.icon">{{
                        !slotProps.option.icon
                          ? slotProps.option.currency_acronym
                          : ""
                      }}</i>-->
                      {{ slotProps.option.currency_name }}</span
                    >
                    <span class="mt-1 text-sm"
                      >1 BUSD =
                      {{ Number(slotProps.option.convert_amount).toFixed(2) }}
                      <!--{{ slotProps.option.currency_acronym }}--></span
                    >
                  </div>
                </template>
              </SelectButton>
              <div class="grid w-full mt-4 payment-form-container">
                <div class="col-12 md:col-7">
                  <div class="grid justify-content-center">
                    <div class="flex flex-column cash-form-input">
                      <label><b>Total a Pagar</b></label>
                      <div
                        class="label-input w-full flex justify-content-between align-items-center"
                      >
                        <i
                          :class="
                            moneda && moneda.convert_amount
                              ? moneda.icon
                              : 'pi pi-spin pi-spinner'
                          "
                          class="ml-3"
                        />
                        {{
                          moneda && moneda.convert_amount
                            ? Number(convertDebt).toFixed(2)
                            : ""
                        }}
                      </div>
                      <label><b>Efectivo Recibido</b></label>
                      <span class="p-input-icon-left">
                        <i :class="moneda ? moneda.icon : ''" />
                        <InputText
                          id="i_cash_received"
                          v-model="cash_received"
                          placeholder="0.00"
                          type="text"
                          :maxlength="9"
                          inputmode="numeric"
                          class="label-input w-full text-right"
                          autofocus
                          @keypress="validNumericInput"
                          @keyup.enter="registerCashPay"
                          autocomplete="off"
                          @blur="setInputFocus('i_cash_received')"
                          :disabled="is_processing_pay"
                        />
                      </span>
                      <label><b>Vuelto</b></label>
                      <div
                        class="label-input w-full flex justify-content-end align-items-center"
                      >
                        {{ Number(cashChange).toFixed(2) }}
                      </div>
                      <div class="inline-flex justify-content-between mt-3">
                        <Button
                          class="keyboard-button clean"
                          :disabled="
                            isNaN(Number(cash_received)) ||
                            is_processing_pay ||
                            Number(cash_received) <= 0 ||
                            convertDebt <= 0
                          "
                          @click="cash_received = ''"
                        >
                          <icon-clean /> Limpiar
                        </Button>
                        <Button
                          class="keyboard-button pay"
                          @click="registerCashPay"
                          :disabled="
                            isNaN(Number(cash_received)) ||
                            is_processing_pay ||
                            Number(cash_received) <= 0 ||
                            convertDebt <= 0
                          "
                          :loading="is_processing_pay"
                        >
                          <icon-pay /> Pagar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hidden md:flex md:col-5 md:justify-content-center align-items-center">
                  <numeric-keyboard
                    inputID="i_cash_received"
                    :value="String(cash_received)"
                    @changed="(value) => (cash_received = value)"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <!----->
        </TabView>
      </div>
      <div class="col-12 md:col-fixed ml-1 lg:ml-0 max-h-full relative" style="max-width: 460px;">
        <ticket-preview scrollHeight="calc(100% - 320px)" scrollMaxHeight="100%" :showPayButton="false"/>
      </div>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script>
import IconPay from "@/components/svg/icons/IconPay.vue";
import IconClean from "@/components/svg/icons/IconClean.vue";
import SelectButton from "primevue/selectbutton";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import NumericKeyboard from "../NumericKeyboard.vue";
import PaymentSummary from "../PaymentSummary.vue";
import WalletPaymentMethod from "../forms/WalletPaymentMethod.vue";
import TicketPreview from "../TicketPreview.vue";
// import BinancePay from "../BinancePay.vue";
import FormPayment from "../FormPayment.vue";
// import Web3Pay from "../Web3Pay.vue";

export default {
  components: {
    SelectButton,
    NumericKeyboard,
    PaymentSummary,
    // BinancePay,
    FormPayment,
    // Web3Pay,
    IconClean,
    IconPay,
    WalletPaymentMethod,
    TicketPreview
  },
  //temporal
  emits: ["registerBNBTransfer", "showPrint"],
  //temporal
  data() {
    return {
      is_processing: false,
      moneda: "Bs",
      submitted: false,
      cash_received: "",
      selectedTabIndex: 0,
      binancePay_visible: "true",
      transaction_type: 0,
      payment_transfer: false,
      payment_mobilePayment: false,
      transaction_transfer: false,
      condition: true,
      pay_method_select: true,
      url: "/ticketoffice-online",
      pay_method_structure: null,
      pay_method_array: null,
      amount_to_pay: null,
      webTest: false,
      messageInputError: "",
      sendVerificationCode: false,
      isVerifing: false,
    };
  },
  created() {},
  methods: {
    ...mapActions("ticketoffice", [
      "registerPay",
      "registerBNBPay",
      "registerBinancePay",
      "generateBinanceOrder",
      "getCurrencies",
      "generatePayMethods",
      // "getBankInfo",
      "generatePayMethodsDetail",
      "deletePayMethodsDetailData",
      "getUserBalance", "creditPay", "creditClientPay", "validateClientWalletCodePay", "verificationSMSCode"
    ]),
    ...mapActions("notification", ["getListTypeNotifications"]),
    ...mapMutations("ticketoffice", [
      "setActiveClient",
      "clearCurrentPlayData",
      "setGeneratePayMethods",
      "clearCurrentPlayData",
      // "setGetBankInfo",
    ]),
    //temporal
    showWeb3(convert_amount) {
      //
      // this.pay_method_select = true;
      // this.generatePayMethodsDetail(pay_method_selected);
      this.amount_to_pay =
        Number(this.currentPlayData.ticket.debt) * Number(convert_amount);
      // this.pay_method_structure = fk_payment_method_structure;
      this.webTest = true;
    },
    //temporal

    selectPayMethod(method) {
      this.pay_method_select = true;
      this.deletePayMethodsDetailData().then(() => {
        if (method === "Efectivo") {
          this.pay_method_array = this.payMethodsArray.filter(
            (item) => item.keywords[0] === "Efectivo"
          );
          this.moneda = this.pay_method_array[0];
          this.generatePayMethodsDetail(this.moneda.id);
        }
        else if (method === "Bolívares") {
          let item = this.payMethods.payMethods.data.find((e) => e.name === "Pago móvil");
            if(item)
            this.payMethodSelected(item.id, item.fk_payment_method_structure, item.convert_amount);
        }
      });
      this.webTest = false;
    },
    payMethodSelected(
      pay_method_selected,
      fk_payment_method_structure,
      convert_amount
    ) {
      this.pay_method_select = false;
      this.generatePayMethodsDetail(pay_method_selected);
      this.amount_to_pay =
        Number(this.currentPlayData.ticket.debt) * Number(convert_amount);
      this.pay_method_structure = fk_payment_method_structure;
    },
    onShow() {
      this.getCurrencies().then(() => {
        this.generatePayMethods().then(async () => {
          await this.getListTypeNotifications();
          this.pay_method_array = this.payMethodsArray.filter(
            (item) => item.keywords[0] === "Efectivo"
          );
          this.moneda = this.pay_method_array[0];
          this.generatePayMethodsDetail(this.moneda.id);
        });
      });

      this.submitted = false;
      this.is_processing = false;
      this.selectedTabIndex = 0;
      this.cash_received = "";
      setTimeout(() => {
        if (
          this.$route.path === "/ticketoffice-online" ||
          (this.$route.path === "/ticketoffice" &&
            this.$store.getters["auth/role"] === "usuario")
        ) {
          this.selectedTabIndex = 0;
        } else {
          this.selectedTabIndex = 0;
        }
      }, 155);
    },
    onHide() {
      this.deletePayMethodsDetailData();
      this.pay_method_select = true;
    },
    validNumericInput(event) {
      if (
        !/\d/.test(event.key) &&
        (event.key !== "." || /\./.test(event.target.value))
      )
        return event.preventDefault();
    },
    setInputFocus(input_id) {
      setTimeout(() => {
        let elem = document.getElementById(input_id);
        if (elem) {
          elem.focus();
        }
      }, 160);
    },
    registerWalletPay(data) {
      this.creditPay(data)
      .then((resp) => {
          this.pay_method_select = true;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Pago Registrado con Exito.",
            life: 4000,
          });
          this.cash_received = "";
          this.cleaningFunction()
          this.getUserBalance();
          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
      .catch((err) => {
        this.$store.getters["ticketoffice/currentPlayData"];
        if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.message,
              life: 6000,
            });
            if(err.response.data.message == "Esta factura esta paga" || err.response.data.message == "Este ticket no contiene deuda."){
              this.$emit("showPrint", true);
            }
          }
      })
    },
    cleaningFunction(){
      this.isVerifing = false
    },
    sendWalletClientPay(data) {
      this.creditClientPay(data)
      .then((resp) => {
          this.pay_method_select = true;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Al cliente se le informará el siguiente paso vía Whatsapp.",
            life: 8000,
          });
          this.isVerifing = true;
          this.sendVerificationCode = true;
          this.cash_received = "";
          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
      .catch((err) => {
        if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.message,
              life: 6000,
            });
            
          }
      })
    },
    getVerificationSMSCode(data) {
      this.verificationSMSCode(data)
      .then((resp) => {
          this.pay_method_select = true;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Al cliente se le informará el siguiente paso vía Whatsapp.",
            life: 8000,
          });
          this.isVerifing = true;
          this.sendVerificationCode = true;
          this.cash_received = "";
          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
      .catch((err) => {
        if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.message,
              life: 6000,
            });
            
          }
      })
    },
    sendWalletClientVerificationCode(data) {
      this.validateClientWalletCodePay(data)
      .then((resp) => {
          this.pay_method_select = true;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Pago Registrado con Exito.",
            life: 4000,
          });
          this.cash_received = "";
          this.cleaningFunction();
          this.getUserBalance();
          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
      .catch((err) => {
        if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: "Error en código de verificación.",
              life: 6000,
            });
          }
      })
    },
    creditPayment() {
      this.$store
        .dispatch("ticketoffice/creditPay", this.currentPlayData.ticket.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Pago Registrado con Exito.",
              life: 4000,
            });
            this.$emit("showPrint", true);
            this.getUserBalance()
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.message,
              life: 6000,
            });
          }
        })
    },
    registerCashPay() {
      if (isNaN(Number(this.cash_received)) || Number(this.cash_received) <= 0)
        return;
      let amount =
        Number(this.cash_received) > Number(this.convertDebt)
          ? Number(this.convertDebt)
          : Number(this.cash_received);

      let payment_method_detail =
        this.payMethodsDetail.payMethodsDetail.data[0];
      this.registerPay({
        order_id: this.currentPlayData.ticket.id,
        data: {
          fk_payment_method_detail: payment_method_detail.id,
          amount: amount.toFixed(9),
        },
      })
        .then((resp) => {
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Pago Registrado con Exito.",
            life: 4000,
          });
          this.cash_received = "";

          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.errors ? this.getFirstError(err.response.data.errors) : err.response.data.message,
              life: 6000,
            });
          }
        });
    },
    showBinancePay(visible) {
      this.binancePay_visible = visible;
      let dataBinance = {
        fk_pay_order_id: this.currentPlayData.ticket.id,
        amount: Number(this.currentPlayData.ticket.debt),
        fk_client: this.currentPlayData.ticket.fk_client,
      };
      this.generateBinanceOrder(dataBinance);
    },
    registerBnbPay() {
      this.registerBinancePay({
        transaction_id: this.binancePayOrder.transaction,
      })
        .then((resp) => {
          if (resp.data.data.binance_status == "SUCCESS") {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Pago Registrado con Exito.",
              life: 4000,
            });
            if (resp.data.data.get_total_due <= 0) {
              this.$emit("showPrint", true);
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              // detail: err.response.data.errors[0].detail,
              life: 6000,
            });
          }
        });
    },
    successTransferenceVes(due){
      this.pay_method_select = true;
      this.$store.dispatch("changeSystemMessage", {
        severity: "success",
        detail: "Pago Registrado con Exito.",
        life: 4000,
      });
      this.cash_received = "";

      if (due <= 0) {
        this.$emit("showPrint", true);
      }
    },
    registerTransferenciaVes(reference, date, amount) {
      let pay_method_detail_selected =
        this.payMethodsDetail.payMethodsDetail.data[0].id;
        this.registerPay({
          order_id: this.currentPlayData.ticket.id,
          data: {
            amount: amount.toFixed(9),
            fk_payment_method_detail: pay_method_detail_selected,
            external_reference: reference,
            request_date: date,
          },
        })
        .then((resp) => {
          this.pay_method_select = true;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Pago Registrado con Exito.",
            life: 4000,
          });
          this.cash_received = "";
          this.successTransferenceVes(resp.data.data.total_due)
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.errors ? this.getFirstError(err.response.data.errors) : err.response.data.message,
              life: 6000,
            });
          }
        });
    },
    //temporal
    registerBNBTransfer(reference, date, amount) {
      let pay_method_detail = 3;
      this.registerBNBPay({
        order_id: this.currentPlayData.ticket.id,
        data: {
          amount: amount,
          fk_payment_method_detail: pay_method_detail,
          external_reference: reference,
          request_date: date,
        },
      })
        .then((resp) => {
          this.pay_method_select = true;
          this.webTest = false;
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Pago Registrado con Exito.",
            life: 4000,
          });
          this.cash_received = "";

          if (resp.data.data.total_due < 0.01) {
            this.$emit("showPrint", true);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: "Fallo al Registrar el Pago.",
              detail: err.response.data.errors ? this.getFirstError(err.response.data.errors) : err.response.data.message,
              life: 6000,
            });
          }
        });
    },
    //temporal
    payMethodDetail(payMethodSelected) {
      this.generatePayMethodsDetail(payMethodSelected).then(() => {});
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "currentPlayData"]),
    ...mapState("ticketoffice", [
      "is_processing_pay",
      "payOptions",
      "monedaOptions",
      "binancePayOrder",
      "payMethods",
      "payMethodsArray",
      "payMethodsDetail",
    ]),
    currentPlayTotal() {
      if (this.currentPlayData && this.currentPlayData.plays.length)
        return this.currentPlayData.plays.reduce(
          (a, b) => a + Number(b.price),
          0
        );
      else return 0;
    },
    cashChange() {
      if (isNaN(Number(this.cash_received))) return 0;
      else
        return Number(this.cash_received) > this.convertDebt
          ? Number(this.cash_received) - this.convertDebt
          : 0;
    },
    convertDebt() {
      return this.currentPlayData &&
        this.currentPlayData.ticket &&
        this.moneda &&
        !isNaN(this.moneda.convert_amount) &&
        !isNaN(this.currentPlayData.ticket.debt)
        ? Number(this.currentPlayData.ticket.debt) *
            Number(this.moneda.convert_amount)
        : Number(0);
    },
  },
  watch: {
    selectedTabIndex(newVal) {
      if (newVal === 3) this.setInputFocus("i_cash_received");
    },
  },
};
</script>

<style lang="scss" scoped>
.new-input {
  background-color: transparent;
  border-style: none;
  border-bottom: 1px solid #CC195A;
  border-radius: 0;
  height: 3rem;
  width: 15rem;
  margin: 1rem;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 30px;
  color: #404040;
}
.get-ticket-button {
  height: 3.5rem;
  background-color: #5FAF58; 
  border-style: none; 
  border-radius: 5px; 
  padding: 1rem; 
  color: white;
  gap: 1rem; 
  font-family: 'Roboto'; 
  font-weight: 700; 
  font-size: 20px;
}

.info-section {
  width: 40rem; 
  background-color: #EDEDED; 
  border-style: none; 
  border-radius: 10px; 
  padding: 1rem;
}
.wallet-acept-button {
  background-color: transparent;
  border: 1px solid #404040  !important;
  border-radius: 6px !important;
  color: #404040  !important;
  font-weight: 700;
  font-size: 16px;
}
:deep(.p-selectbutton.currency-select-buttons .p-button) {
  border-width: 1px 1px 1px 7px !important;
  border-style: solid !important;
  border-color: #404040 !important;
  border-radius: 6px !important;
  margin-right: 8px !important;
  &.p-highlight {
    background-color: #404040 !important;
    color: #ffffff;
  }
  &:last-of-type {
    margin-right: 0px !important;
  }
}

:deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
  height: 100%;
}
.title-pay-tab {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.payment-method-button {
  border-style: solid;
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}
.payButton {
  background-color: transparent;
  border: 1px solid #91c157;
  border-radius: 6px;
  border-left: 5px solid #91c157;
  color: #004987;
  width: 250px;
}
.binanceButton {
  border: 1px solid #ffd900;
  border-left: 5px solid #ffd900;
  color: #000000;
}

.paypalButton {
  border: 1px solid #0070ba;
  border-left: 5px solid #00ba47;
  color: #0070ba;
}
.zelleButton {
  border: 1px solid #5b18b2;
  border-left: 5px solid #5b18b2;
  color: #5b18b2;
}
:deep(.pi.titleicon-pay-tab) {
  font-size: 24px;
  font-weight: 900;
  color: #CC195A;
}
:deep(.p-buttonset .p-button) {
  margin: 0;
  height: 70px;
  width: 130px;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  :deep(.p-buttonset .p-button) {
    width: 100px;
    font-size: 0.8rem !important;
    & .text-sm {
      font-size: 0.7rem !important;
    }
  }
}
:deep(.label-input) {
  height: 50px;
  background: #d7d7d7;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-right: 10px;
  font-size: 36px;
  font-weight: 700;
}
:deep(input.p-inputtext) {
  background: #ffffff !important;
}

.binancelogo {
  margin-left: 0.5rem;

  border-radius: 6px;
}
.binancelogo:hover {
  cursor: pointer;
  overflow: hidden;
}
.btn {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  height: 70px;
  width: 120px;
  justify-content: center;
  background: #fff;
  border-color: #e0e0e1;
  border: 4px solid rgba(158, 155, 155, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

:deep(.p-tabview-nav-content) {
  display: flex;
  justify-content: center;
}

:deep(.p-tabview-nav) {
  flex: unset;
  border: 0px;
  justify-content: center;
  width: 100%;
}

:deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  background: #CC195A;
  border-radius: 6px 6px 0px 0px;
  color: #ffffff;
}
:deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  .pi.titleicon-pay-tab,
  .title-pay-tab {
    color: #ffffff;
  }
}

.cash-form-input {
  max-width: 95%;
  width: 340px;
  label {
    margin-top: 15px;
  }
  .p-button {
    display: flex;
    flex-direction: column;
    background: #f1f1f1;
    width: 131px;
    min-height: 90px;
    font-size: 24px;
    border-radius: 1px;
    border: 1px solid rgba(255, 160, 1, 0.25);
    &.clean {
      color: #ffa001;
    }
    &.pay {
      color: #404040;
      &:enabled:hover { 
        color: #f1f1f1 !important;
        svg {
          fill: #f1f1f1 !important;
        }
      }
    }
    &.p-disabled {
      svg {
        fill: rgba(0, 0, 0, 0.38) !important;
      }
    }
    &:enabled:hover {
      background: rgb(50, 50, 50, 100);
    }
  }
}
/* :deep(.p-tabview .p-tabview-nav li) {
  padding-right: 5px;
} */
</style>

<template>
  <svg
    version="1.2"
    fill="#ffa001"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 35"
    width="31"
    height="35"
  >
    <path
      id="Layer"
      class="s0"
      d="m29.4 0q-0.4 0-0.9 0.1-0.4 0.2-0.6 0.7l-5.6 8.9-1.6-1c-0.5-0.3-1.2-0.4-1.8-0.3-0.6 0.1-1.1 0.5-1.5 1l-1.5 1.9 12.6 8.1 0.7-2.5c0.1-0.7 0.1-1.4-0.1-2.1-0.2-0.6-0.7-1.1-1.2-1.5l-1.2-0.7 3.9-10.1q0.2-0.4 0.1-0.8 0-0.4-0.2-0.8-0.1-0.3-0.4-0.6-0.3-0.2-0.7-0.3zm-15.3 13c-6.3 7.4-14.1 7.6-14.1 7.6l6.3 4.4c0 0 2.9 0.3 4.4 0-1.4 1-2.2 1.5-2.2 1.5l3.4 2.4c0 0 3.6-0.2 8.2-3.3-0.9 2.2-4 5.9-4 5.9l4.8 2.6c0 0 4-3.3 6.8-12.4z"
    />
  </svg>
</template>

<template>
  <div class="flex flex-column ml-2 keyboard-container">
    <div v-for="(item, index) in keys" class="flex flex-row" :key="index">
      <Button
        v-for="key in item"
        :class="classButtons"
        :label="key.label"
        @click="keyboardInput(key.key)"
        :disabled="disabled"
        :key="key.key"
        :icon="key.icon"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    classButtons: {
      type: String,
      default: "keyboard-button",
    },
    inputID: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      keys: [
        [
          { key: "1", label: "1" },
          { key: "2", label: "2" },
          { key: "3", label: "3" },
        ],
        [
          { key: "4", label: "4" },
          { key: "5", label: "5" },
          { key: "6", label: "6" },
        ],
        [
          { key: "7", label: "7" },
          { key: "8", label: "8" },
          { key: "9", label: "9" },
        ],
        [
          { key: "DEL", label: "", icon: "pi pi-delete-left" },
          { key: "0", label: "0" },
          { key: ".", label: "." },
        ],
      ],
      input_data: "",
    };
  },
  created() {
    this.input_data = String(this.value);
  },
  methods: {
    keyboardInput(input) {
      if (!this.disabled) {
        if (input === "DEL") this.input_data = "";
        else if (input === ".") {
          if (this.input_data.length && this.input_data.indexOf(".") === -1)
            this.input_data += String(input);
          else return;
        } else this.input_data += String(input);
      }
      this.$parent.setInputFocus();
    },
  },
  watch: {
    input_data(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("changed", newVal);
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.input_data = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
.keyboard-container {
  max-width: 500px;
  background: #f1f1f1;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  padding: 10px;
}
</style>
